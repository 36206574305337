import React, { memo } from 'react';

import ErrorBoundary from './ConnectedErrorBoundary';

const withErrorBoundary = Component => memo(props => (
  <ErrorBoundary>
    <Component {...props} />
  </ErrorBoundary>
));

export default withErrorBoundary;
