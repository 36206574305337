import styled from 'styled-components';

const Container = styled.div`
  display: inline-block;
  position: relative;
`;

const Text = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: ${(props) => props.theme.palette.tertiary.dark};
  color: ${(props) => props.theme.palette.tertiary.contrastText};
  text-align: center;
  padding: 5px;
  border-radius: 6px;

  position: absolute;
  z-index: 9999;

  ${Container}:hover & {
    visibility: visible;
  }

  ${(props) => props.position === 'left' && `
    top: calc(50% - 1em);
    right: 105%; 
  `};

  ${(props) => props.position === 'right' && `
    top: calc(50% - 1em);
    left: 105%; 
  `};

  ${(props) => props.position === 'top' && `
    bottom: calc(100% + 0.5em);
    left: 50%; 
    margin-left: -60px;
  `};

  ${(props) => props.position === 'bottom' && `
    top: calc(100% + 0.5em);
    left: 50%; 
    margin-left: -60px;
  `};

`;

export default {
  Container,
  Text,
};
