// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/browser';

import Button from 'core/Button';

const MassiveHeader = styled.h1`
  font-size: 150px;
  font-weight: bold;
  color: ${props => props.theme.palette.secondary.main};
`;

const Subtitle = styled.h3`
  font-weight: bold;
  line-height: 2;
`;

const ErrorMessage = styled.pre`
  font-family: monospace !important;
  line-height: 1.5;
  margin-bottom: 2em;
`;

type Props = {
  title: string,
  subtitle: string,
  errorMessage?: string,
};

export default function TextContent({ title, subtitle, errorMessage }: Props) {
  return (
    <Fragment>
      <MassiveHeader>{ title }</MassiveHeader>
      <Subtitle>{ subtitle }</Subtitle>
      <ErrorMessage>{ errorMessage }</ErrorMessage>
      <Button
        variant="primary"
        onClick={() => Sentry.showReportDialog()}
      >
        Report feedback
      </Button>
    </Fragment>
  );
}

TextContent.defaultProps = {
  errorMessage: '',
};
