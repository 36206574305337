import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Grid from 'hedron';

import LS from './styles/UpperNavbarItemStyles';

export default class UpperNavbarItem extends PureComponent {

  static propTypes = {
    icon: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    sub: PropTypes.string,
    active: PropTypes.bool,
    flex: PropTypes.bool,
    highlighted: PropTypes.bool,
  }

  static defaultProps = {
    icon: [],
    sub: '',
    active: false,
    flex: false,
    highlighted: false,
    onClick: () => null,
  }

  // TODO do i really need to pass active to all eveyrthing other than
  // the container?
  render() {
    return (
      <LS.Container
        onClick={this.props.onClick}
        fluid
        highlighted={this.props.highlighted}
        active={this.props.active}
        flex={this.props.flex}
      >
        <Grid.Bounds
          direction="horizontal"
          valign="center"
        >
          <LS.IconHolder fluid>
            <LS.Icon
              active={this.props.active}
              highlighted={this.props.highlighted}
              icon={this.props.icon}
            />
          </LS.IconHolder>
          <Grid.Box fluid>
            <LS.Title
              highlighted={this.props.highlighted}
              active={this.props.active}
            >
              {this.props.title}
            </LS.Title>
            <LS.Sub
              active={this.props.active}
              highlighted={this.props.highlighted}
            >
              {this.props.sub}
            </LS.Sub>
          </Grid.Box>
        </Grid.Bounds>
      </LS.Container>
    );
  }

}
