// configuration
export const applicantsAuthConfig = {
  // REQUIRED - Amazon Cognito Identity Pool ID
  identityPoolId: process.env.REACT_APP_AWS_COGNITO_APPLICANTS_IDENTITY_POOL_ID ?? 'eu-west-2:c5b0cd74-9e45-4497-9f48-2b390bca5d19',
  // REQUIRED - Amazon Cognito Region
  region: process.env.REACT_APP_AWS_COGNITO_REGION ?? 'eu-west-2',
  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: process.env.REACT_APP_AWS_COGNITO_APPLICANTS_USER_POOL_ID ?? 'eu-west-2_wndQoQ02K',
  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_APPLICANTS_WEB_CLIENT_ID ?? '5q13m9ge5ska18k8qjp0b13q0u',
};

export const clientsAuthConfig = {
  // REQUIRED - Amazon Cognito Identity Pool ID
  identityPoolId: process.env.REACT_APP_AWS_COGNITO_CLIENTS_IDENTITY_POOL_ID ?? 'eu-west-2:f12ca9e1-8434-44db-a3ad-4c03ffa19622',
  // REQUIRED - Amazon Cognito Region
  region: process.env.REACT_APP_AWS_COGNITO_REGION ?? 'eu-west-2',
  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: process.env.REACT_APP_AWS_COGNITO_CLIENTS_USER_POOL_ID ?? 'eu-west-2_kRQxwV7iy',
  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_CLIENTS_WEB_CLIENT_ID ?? 'oo53pd6gjs1k0a2jur82fkpj4',
};
