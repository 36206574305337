import axios from 'axios';

const { REACT_APP_API } = process.env;

const Api = axios.create();

Api.defaults.headers.patch['Content-Type'] = 'application/json';
Api.defaults.headers.put['Content-Type'] = 'application/json';
Api.defaults.headers.post['Content-Type'] = 'application/json';

export const setAuthHeader = (token) => {
  Api.defaults.headers.common.Authorization = `${token}`;
};

export const setApiUserGroup = (userGroup) => {
  Api.defaults.baseURL = `${REACT_APP_API}/${userGroup}`;
};

export default Api;
