import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import LS from './styles/TooltipStyles';

export default class Tooltip extends PureComponent {

  static propTypes = {
    text: PropTypes.string.isRequired,
    children: PropTypes.object, // eslint-disable-line
    position: PropTypes.oneOf(['left', 'right', 'top', 'bottom']).isRequired,
  }

  render() {
    if (!this.props.text) {
      return this.props.children;
    }
    return (
      <LS.Container>
        {this.props.children}
        <LS.Text position={this.props.position}>{this.props.text}</LS.Text>
      </LS.Container>
    );
  }

}
