// @flow
import TagManager from 'react-gtm-module';

const GTM_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

export const initTagManager = () => {
  if (!GTM_ID) {
    return;
  }
  TagManager.initialize({
    gtmId: GTM_ID,
  });
};
