// @flow
import PropTypes from 'prop-types';

const HistoryPropTypes = PropTypes.shape({
  length: PropTypes.number.isRequired,
  action: PropTypes.oneOf(['PUSH', 'POP', 'REPLACE']),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
    key: PropTypes.string,
  }),
  block: PropTypes.func.isRequired,
  createHref: PropTypes.func,
  go: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  goForward: PropTypes.func.isRequired,
  listen: PropTypes.func,
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
});

export type History = {
  replace: (newUrl: string) => void,
}

export default HistoryPropTypes;
