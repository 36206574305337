import styled, { css } from 'styled-components';

export const NoText = styled.p`
  margin: 0;
  padding: 20px;
  font-size: 25px;
  font-style: italic;
  text-align: center;
  color: ${(props) => props.theme.palette.text.main};
`;

const PageTitle = styled.h1`
  font-size: 48px;
  font-weight: bold;
  margin: 0px 20px 20px 0px;
  color: ${(props) => props.theme.palette.text.main};
`;

const PageTitleHighlight = styled.span`
  color: ${(props) => props.theme.palette.primary.main};
`;

const Button = styled.button`
  border-style: none;
  border-radius: 0.15em;
  font-size: 0.8em;
  font-weight: bold;
  padding: 1.1em 3.7em;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: transparent;

  &[disabled] {
    background-color: ${(props) => props.theme.palette.grey['500']};
  }

`;

// line-height: 1.94;
export const Input = styled.input`
  padding: 10px 45px;
  border: 2px solid ${(props) => props.theme.palette.grey['800']};
  border-radius: 5px;
  font-size: 18px;
  height: 45px;
  width: 100%;
  box-sizing: border-box;
  color: ${(props) => props.theme.palette.text.main};
`;

export const Select = styled.select`
  padding: 10px 45px;
  border: 2px solid ${(props) => props.theme.palette.grey['800']};
  border-radius: 5px;
  font-size: 18px;
  height: 45px;
  width: 100%;
  box-sizing: border-box;
  color: ${(props) => props.theme.palette.text.main};
  background-color: ${(props) => props.theme.palette.background.lighter};
`;

export const InputError = {
  'border-left': '10px solid #E24C4C',
};

export const TextArea = styled.textarea`
  resize: vertical;
  padding: 10px 45px 10px 45px;
  border: 2px solid ${(props) => props.theme.palette.grey['800']};
  border-radius: 5px;
  font-size: 18px;
  line-height: 1.94;
  width: 100%;
  box-sizing: border-box;
  color: ${(props) => props.theme.palette.text.main};
`;

export const PrimaryButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  cursor: pointer;
`;

export const PrimaryTransButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.main};
  background-color: transparent;
  border: 2px solid ${(props) => props.theme.palette.primary.main};
  cursor: pointer;
`;

export const DarkButton = styled(Button)`
  color: ${(props) => props.theme.palette.tertiary.contrastText};
  background-color: ${(props) => props.theme.palette.tertiary.main};
  cursor: pointer;
`;

export const RedButton = styled(Button)`
  color: ${(props) => props.theme.palette.error.contrastText};
  background-color: ${(props) => props.theme.palette.error.light};
  cursor: pointer;
`;

export const Hint = styled.span`
  ${(props) => props.type === 'successfull' && css`
    color: ${props.theme.palette.primary.light};
  `}; 

  ${(props) => props.type === 'error' && css`
    color: ${props.theme.palette.error.main};
  `}; 
`;

export default {
  PrimaryButton,
  Hint,
  Select,
  RedButton,
  Input,
  TextArea,
  InputError,
  PageTitle,
  PageTitleHighlight,
  PrimaryTransButton,
  DarkButton,
  NoText,
};
