import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const tokenSelector = (state) => {
  if (!state.user.signInUserSession) {
    return '';
  }

  return state.user.signInUserSession.idToken.jwtToken;
};

const configureApolloClient = (store) => {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT ?? 'https://graphql-beta.vizcareer.com/graphql',
  });

  const authLink = setContext((_, { headers }) => {
    const state = store.getState();
    // get the authentication token from local storage if it exists
    const token = tokenSelector(state);
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token,
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return client;
};

export default configureApolloClient;
