import React, { memo, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import Grid from 'hedron';
import { ApolloProvider } from '@apollo/client';
import { ToastContainer } from 'react-toastify';

import getTheme from 'styles/getTheme';
import configureStore from 'state/configureStore';
import breakpoints from 'styles/breakpoints';
import Router from 'utils/Router';
import loadIcons from 'utils/FontAwesomeLoader';
import ErrorBoundary from 'utils/ErrorBoundary';
import configureApolloClient from 'utils/configureApolloClient';

const { store, persistor } = configureStore();

function App() {
  const [theme, setTheme] = useState(null);
  const loadTheme = async () => {
    const loadedTheme = await getTheme();
    setTheme(loadedTheme);
  };

  useEffect(() => {
    loadIcons();
    loadTheme();
  }, []);

  if (!theme) return null;

  return (
    <Provider store={store}>
      <ApolloProvider client={configureApolloClient(store)}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <Grid.Provider
              padding="15px"
              breakpoints={breakpoints}
            >
              <ErrorBoundary includeFrame={false}>
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
                <Router />
              </ErrorBoundary>
            </Grid.Provider>
          </ThemeProvider>
        </PersistGate>
      </ApolloProvider>
    </Provider>
  );
}

App.whyDidYouRender = true;

export default memo(App);
