const userSelector = (state) => {
  if (!state.user?.attributes) {
    return null;
  }

  const u = state.user.attributes;

  return {
    id: u.sub,
    firstName: u.given_name,
    lastName: u.family_name,
    email: u.email,
    subscriptionActive: u['custom:subscriptionStatus'] === 'ON',
    company: u['custom:company'] || null,
  };
};

export default userSelector;
