export const lighten = (color, percent) => {
  const f = parseInt(color.slice(1), 16);
  const t = percent < 0 ? 0 : 255;
  const p = percent < 0 ? percent * -1 : percent;
  /* eslint-disable no-bitwise, no-mixed-operators */
  const R = f >> 16;
  const G = f >> 8 & 0x00FF;
  const B = f & 0x0000FF;
  /* eslint-enable */
  return `#${(0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1)}`;
};

export const darker = (color, percent) => lighten(color, -percent);

export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null;
};

export const alpha = (color, percent) => {
  let rgb;
  if (typeof color === 'string') {
    rgb = hexToRgb(color);
  } else {
    rgb = color;
  }
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${percent})`;
};
