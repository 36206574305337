import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { applyVariant } from './helpers';

export const Spinner = styled.div`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation: spin 0.5s ease-out infinite;
  border-top: 2px solid transparent;
  margin: 0 auto;
`;

const StyledBaseButton = styled.button`
  border-style: none;
  border-radius: 5px;
  font-size: 0.8em;
  font-weight: bold;
  padding: 1.1em 3.7em;
  text-transform: uppercase;
  cursor: pointer;
  ${props => applyVariant(props.variant, props.theme, props.loading)}

  &[disabled] {
    color: ${props => props.theme.palette.text.main};
    background-color: ${props => props.theme.palette.background.dark};
  }

  & > ${Spinner} {
    &:first-child {
      border-top: 2px solid transparent;
    }
  }

  ${props => props.$borderRadius && css`
    border-radius: ${props.$borderRadius}
  `}
  
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: 0.25em;
`;

export default class Button extends PureComponent {

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
    loading: PropTypes.bool,
    variant: PropTypes.oneOf(['', 'primary', 'secondary', 'secondaryLight', 'danger', 'white']),
    borderRadius: PropTypes.string,
  }

  static defaultProps = {
    className: '',
    onClick: () => null,
    icon: [],
    loading: false,
    variant: '',
    borderRadius: undefined,
  }

  onClick = ev => (this.props.loading ? null : this.props.onClick(ev));

  render() {
    const {
      children,
      className, onClick, icon, loading,
      borderRadius,
      ...props
    } = this.props;

    const Children = () => (
      <Fragment>
        { icon.length > 0 && <StyledIcon icon={icon} /> }
        { children }
      </Fragment>
    );

    return (
      <StyledBaseButton
        {...props}
        className={className}
        onClick={this.onClick}
        loading={loading}
        $borderRadius={borderRadius}
      >
        { loading ? <Spinner /> : <Children />}
      </StyledBaseButton>
    );
  }

}
