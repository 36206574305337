import { memo } from 'react';
import { withRouter } from 'react-router-dom';

import PageFrame from './PageFrame';
import UpperNavbarItem from './UpperNavbarItem';

export { UpperNavbarItem };
export default withRouter(memo(
  PageFrame,
  (prevProps, nextProps) => {
    const prevJobId = prevProps.match.params.jobId;
    const nextJobId = nextProps.match.params.jobId;
    return prevJobId !== nextJobId;
  },
));
