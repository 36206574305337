import styled, { css } from 'styled-components';
import Grid from 'hedron';

import { cssFrom } from 'styles/breakpoints';

const Content = styled(Grid.Box)`
  background-color: ${(props) => props.theme.palette.background.main};
  min-height: calc(100vh - 64px);
  position: relative;

  padding: ${(props) => (props.fill ? '0' : '1.8em')};
  margin-top: ${(props) => (props.fill ? '0' : '64px')};
  margin-left: ${(props) => (props.fill ? '0' : '4.9em')};

  ${(props) => props.emptyNavbar && `
    margin-top: 0px;
  `};

  ${cssFrom.md} {
    margin-top: 0px;
    margin-left: 0px;
    max-width: 100vw;
  }

  ${cssFrom.sm} {
    padding: ${(props) => (props.fill ? '0' : '0.9em')};
    margin-top: ${(props) => (props.fill ? '0' : '5.2em')};
  }
`;

const ContentNoUser = styled.div`
  box-sizing: border-box;
  background-color: ${(props) => props.theme.palette.background.main};
  position: relative;
  min-height: calc(100vh - 64px);

  padding: ${(props) => (props.fill ? '0' : '1.8em')};
  margin-top: ${(props) => (props.fill ? '0' : '5.4em')};

  ${cssFrom.sm} {
    margin-top: 0px;
  }
`;

const UpperNavbarContainer = styled(Grid.Bounds)`
  background-color: ${(props) => props.theme.palette.background.light};
  border-bottom: 2px solid ${(props) => props.theme.palette.grey['700']};
  margin-left: 4.79em;
  height: 64px;
  width: 100%;
  position: fixed;
  z-index: 999;

  ${(props) => props.empty && css`
    display: none;
  `};

  ${cssFrom.md} {
    margin-left: 0px;
    overflow-y: auto;
    position: relative;
    max-width: 100vw;

  }

  ${cssFrom.sm} {
    position: fixed;
    margin-top: 5.2em;
    height: auto;
    overflow: hidden;
  }
`;

const UpperNavbarContainerNoUser = styled.div`
  background-color: ${(props) => props.theme.palette.tertiary.main};
  border-bottom: 2px solid ${(props) => props.theme.palette.primary.main};
  width: 100%;
  padding: 1.2em 1.4em;
  position: fixed;
  z-index: 999;

  ${cssFrom.sm} {
    position: relative;
  }
`;

const ButtonHolder = styled.div`
  float: right;
  & > * {
    margin-right: 1em;
  }

  & > *:last-child {
    margin-right: 0px;
  }

  ${cssFrom.sm} {
    text-align: center;
    float: none;

    & > * {
      margin: 0.5em;
    }

    & > *:last-child {
      margin: 0.5em;
    }
  }
`;

const Logo = styled.img`
  ${cssFrom.sm} {
    width: 100%;
    margin-bottom: 2em;
  }
`;

export default {
  Content,
  UpperNavbarContainer,
  ButtonHolder,
  UpperNavbarContainerNoUser,
  ContentNoUser,
  Logo,
};
