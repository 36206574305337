import Authenticator from './Authenticator';
import withAuthenticator from './withAuthenticator';
import AuthenticatorSubject, { mapPoolIdToSubject, subjectSelector } from './AuthenticatorSubject';
import userSelector from './userSelector';

export default Authenticator;
export {
  withAuthenticator, AuthenticatorSubject, mapPoolIdToSubject, subjectSelector,
  userSelector,
};
