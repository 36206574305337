import { clientsAuthConfig, applicantsAuthConfig } from 'services/authConfig';

export default class AuthenticatorSubject {
  static CLIENT = 'client';

  static APPLICANT = 'applicant';

  static ANY = 'any';

  static NONE = 'none';

  static values = () => ['client', 'applicant', 'any', 'none'];
}

export const mapPoolIdToSubject = (poolId: string) => {
  switch (poolId) {
    case clientsAuthConfig.userPoolId:
      return AuthenticatorSubject.CLIENT;

    case applicantsAuthConfig.userPoolId:
      return AuthenticatorSubject.APPLICANT;

    default:
      return AuthenticatorSubject.NONE;
  }
};

export const subjectSelector = (state) => {
  if (!state.user.pool) {
    return AuthenticatorSubject.NONE;
  }

  return mapPoolIdToSubject(state.user.pool.userPoolId);
};
