import './wdyr'; // <--- first import
import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css';
import 'reset-css';
import 'react-datepicker/dist/react-datepicker.css';
import 'rc-slider/dist/rc-slider.min.css';
import 'react-quill/dist/quill.snow.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { initTagManager } from 'utils/GoogleTagManager/GoogleTagManager';
import App from './App';

if (process.env.REACT_APP_SENTRY_KEY) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // MW: I disabled it because we have a lot of dummy-errors from old class-components
    tracesSampleRate: 0,
  });
}

if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
  initTagManager();
}

ReactDOM.render(<App />, document.getElementById('root'));
