import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LoadingIndicator from './LoadingIndicator';


const Overlay = styled.div`
  display: ${props => (props.show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  background-color: rgba(255, 255, 255, 0.7);
`;

const Message = styled.p`
  margin-top: 200px;
  display: flex;
  @keyframes bounce {
    0%, 40% {
      transform: scale(1.0);
    }
    50% {
      transform: scale(1.1);
    }
    60% {
      transform: scale(1.0);
    }
    70% {
      transform: scale(1.1);
    }
    80%, 100% {
      transform: scale(1.0);
    }
  }

  animation: bounce 2s infinite;
`;

export default class LoadingOverlay extends PureComponent {

  static propTypes = {
    show: PropTypes.bool,
    customMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }

  static defaultProps = {
    show: true,
    customMessage: false,
  }

  render() {
    const { show, customMessage } = this.props;
    return (
      <Overlay
        show={show}
        halign="center"
        valign="center"
      >
        <LoadingIndicator />
        <Message>
          { (!!customMessage && typeof customMessage === 'string')
            ? customMessage
            : 'Loading...'
          }
        </Message>
      </Overlay>
    );
  }

}
