import React, { PureComponent, Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import LoadingOverlay from 'core/LoadingOverlay';
import { AuthenticatorSubject } from 'utils/Authenticator';
import PrivateJobsContextProvider from 'screens/PrivateJobsContext/PrivateJobs.context';
import PrivateRoute from './PrivateRoute';

const LandingPage = lazy(() => import('screens/LandingPage'));
const ContactPage = lazy(() => import('screens/Contact'));
const AuthPage = lazy(() => import('screens/AuthPage'));
const Jobs = lazy(() => import('screens/Jobs'));
const DashboardApplicant = lazy(() => import('screens/DashboardApplicant'));
const Profile = lazy(() => import('screens/Profile'));
const ReviewApplication = lazy(() => import('screens/Application/ReviewApplication'));
const EditProfile = lazy(() => import('screens/EditProfile'));
const JobDetails = lazy(() => import('screens/JobDetails'));
const NewApplication = lazy(() => import('screens/Application/NewApplication'));
const EditApplication = lazy(() => import('screens/Application/EditApplication'));
const AssessApplication = lazy(() => import('screens/Application/AssessApplication'));
const DashboardClient = lazy(() => import('screens/DashboardClient'));
const NewJob = lazy(() => import('screens/NewJob'));
const Logout = lazy(() => import('screens/Logout'));
const Register = lazy(() => import('screens/Register'));
const ApplicationsList = lazy(() => import('screens/ApplicationsList'));
const ApplicationsSharedList = lazy(() => import('screens/ApplicationsSharedList'));
const ReadOnlyApplication = lazy(() => import('screens/Application/ReadOnlyApplication'));
const { Error404Page } = lazy(() => import('screens/ErrorScreen'));
const Dashboard = lazy(() => import('screens/Dashboard'));
const JobVideo = lazy(() => import('screens/JobVideo'));
const JobEmbed = lazy(() => import('screens/JobEmbed'));
const JobPasswordEntry = lazy(() => import('screens/JobPasswordEntry'));
const Meetings = lazy(() => import('screens/Meetings'));
const LiveInterview = lazy(() => import('screens/LiveInterview'));
const Organization = lazy(() => import('screens/Organization'));
const ActivityFeed = lazy(() => import('screens/ActivityFeed'));

export default class Router extends PureComponent {

  render() {
    return (
      <Suspense fallback={<LoadingOverlay />}>
        <BrowserRouter>
          <PrivateJobsContextProvider>
            <Switch>
              <Route exact path="/" component={LandingPage} />
              <Route exact path="/contact" component={ContactPage} />
              <Route exact path="/auth" render={props => <AuthPage {...props} />} />
              <Route exact path="/auth/logout" component={Logout} />
              <Route exact path="/auth/register" component={Register} />
              <Route exact path="/auth/:subject" render={props => <AuthPage {...props} />} />
              <Route exact path="/dashboard" component={Dashboard} />
              <PrivateRoute subject={AuthenticatorSubject.ANY} exact path="/jobs" component={props => <Jobs {...props} />} />
              <PrivateRoute subject={AuthenticatorSubject.ANY} exact path="/profile" component={Profile} />
              <PrivateRoute subject={AuthenticatorSubject.ANY} exact path="/profile/edit" component={EditProfile} />
              <PrivateRoute subject={AuthenticatorSubject.ANY} exact path="/profile/:userId" component={Profile} />
              <PrivateRoute subject={AuthenticatorSubject.ANY} exact path="/jobs/:jobId/applications/:applicationId/review" component={ReviewApplication} />
              <PrivateRoute subject={AuthenticatorSubject.APPLICANT} exact path="/dashboard/applicant" component={DashboardApplicant} />
              <PrivateRoute subject={AuthenticatorSubject.APPLICANT} exact path="/jobs/:jobId/apply" component={NewApplication} />
              <PrivateRoute subject={AuthenticatorSubject.APPLICANT} exact path="/jobs/:jobId/applications/:applicationId" component={EditApplication} />
              <PrivateRoute subject={AuthenticatorSubject.CLIENT} exact path="/jobs/:jobId/applications/:applicationId/assess" component={AssessApplication} />
              <PrivateRoute subject={AuthenticatorSubject.CLIENT} exact path="/dashboard/client" component={DashboardClient} />
              <PrivateRoute subject={AuthenticatorSubject.CLIENT} exact path="/jobs/:jobId/edit" component={NewJob} />
              <PrivateRoute subject={AuthenticatorSubject.CLIENT} exact path="/jobs/:jobId/edit/step/:step" component={NewJob} />
              <PrivateRoute subject={AuthenticatorSubject.CLIENT} exact path="/organization" component={Organization} />
              <Route exact path="/jobs/:jobId/applications">
                <ApplicationsList />
              </Route>
              <Route exact path="/jobs/:jobId/shared/:shareListId">
                <ApplicationsSharedList />
              </Route>
              <Route exact path="/jobs/:jobId/shared/:shareListId/applications/:applicationId/review">
                <ReadOnlyApplication />
              </Route>
              <PrivateRoute subject={AuthenticatorSubject.CLIENT} exact path="/jobs/new" component={NewJob} />
              <PrivateRoute subject={AuthenticatorSubject.CLIENT} exact path="/live-interview" component={Meetings} />
              <Route subject={AuthenticatorSubject.ANY} exact path="/live-interview/:id">
                <LiveInterview />
              </Route>
              <Route exact path="/jobs/:id" component={JobDetails} />
              <Route exact path="/jobs/:id/video" component={JobVideo} />
              <Route exact path="/jobs/:id/embed" component={JobEmbed} />
              <Route exact path="/jobs/:id/password" component={JobPasswordEntry} />
              <Route exact path="/activity-feed" component={ActivityFeed} />
              <Error404Page />
            </Switch>
          </PrivateJobsContextProvider>
        </BrowserRouter>
      </Suspense>
    );
  }

}
