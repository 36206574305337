// @flow
import React, { useEffect } from 'react';
import * as Sentry from '@sentry/browser';

import { mapPoolIdToSubject } from 'utils/Authenticator/AuthenticatorSubject';

import ErrorScreen from '../ErrorScreen';

type Props = {
  user: {
    username: string,
    attributes: {
      email: string,
      family_name: string,
      given_name: string,
    },
    pool: {
      userPoolId: string,
    },
  },
}

export default function Error404Page(props: Props) {
  const logError = async () => {
    const { user } = props;
    Sentry.withScope((scope) => {
      if (user.username) {
        scope.setUser({
          id: user.username,
          username: `${user.attributes.given_name} ${user.attributes.family_name}`,
          email: user.attributes.email,
        });
      }
      if (user.pool) {
        scope.setExtra('pool', mapPoolIdToSubject(user.pool.userPoolId));
      }
      scope.setLevel('info');
      Sentry.captureMessage('Page not found');
    });
  };

  useEffect(() => {
    logError();
  }, []);

  return (
    <ErrorScreen
      title="404"
      subtitle="Whoops! I think you got lost!"
    />
  );
}
