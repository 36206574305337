import { keyframes } from 'styled-components';

const waterFloat = keyframes`
  from {
    transform: translateY(-5px);
  }

  to {
    transform: translateY(5px);
  }
`;

export default waterFloat;
