// @flow
import React, { PureComponent } from 'react';
import * as Sentry from '@sentry/browser';

import { mapPoolIdToSubject } from 'utils/Authenticator';
import ErrorScreen from 'screens/ErrorScreen';
import type { Node } from 'react';

type Props = {
  children: Node,
  user: {
    username: string,
    attributes: {
      email: string,
      family_name: string,
      given_name: string,
    },
    pool: {
      userPoolId: string,
    },
  },
  includeFrame?: boolean,
};

type State = {
  error: Error | null,
};

export default class ErrorBoundary extends PureComponent<Props, State> {

  state = {
    error: null,
  }

  static defaultProps = {
    includeFrame: true,
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: {}) {
    const { user } = this.props;
    this.setState({ error });

    Sentry.withScope((scope) => {
      if (user.username) {
        scope.setUser({
          id: user.username,
          username: `${user.attributes.given_name} ${user.attributes.family_name}`,
          email: user.attributes.email,
        });
      }
      if (user.pool) {
        scope.setExtra('pool', mapPoolIdToSubject(user.pool.userPoolId));
      }

      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { error } = this.state;
    const { includeFrame } = this.props;

    if (error) {
      return (
        <ErrorScreen
          title="Oops!"
          subtitle="Something went wrong."
          errorMessage={error.message}
          includeFrame={includeFrame}
        />
      );
    }

    return this.props.children;
  }

}
