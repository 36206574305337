import Amplify, { Auth } from 'aws-amplify';

import { Creators } from 'state/User';
import { AuthenticatorSubject, mapPoolIdToSubject } from 'utils/Authenticator';
import { applicantsAuthConfig, clientsAuthConfig } from './authConfig';

export const signIn = (email, password) => Auth.signIn(email, password);
// eslint-disable-next-line
export const federatedSignIn = (provider, response, user) => Auth.federatedSignIn(provider, response, user);

export const forgotPassword = (email) => Auth.forgotPassword(email);

export const forgotPasswordSubmit = (email, code, newPassword) => Auth.forgotPasswordSubmit(email, code, newPassword); // eslint-disable-line

export const signUp = (user) => Auth.signUp(user);

export const currentSession = () => Auth.currentSession();

export const currentAuthenticatedUser = () => Auth.currentAuthenticatedUser();

export const verifyCurrentUserEmailSubmit = (confirmationCode) => Auth.verifyCurrentUserAttributeSubmit('email', confirmationCode);

export const updateUserAttributes = async (userAttributes) => {
  const user = await currentAuthenticatedUser();

  return Auth.updateUserAttributes(user, userAttributes);
};

export const changePassword = async (oldPassword, newPassword) => {
  const user = await currentAuthenticatedUser();

  return Auth.changePassword(user, oldPassword, newPassword);
};

export const signOut = async (dispatch, history) => {
  if (dispatch === undefined) throw new Error('dispatch should be passed as parameter!');
  if (history === undefined) console.warn('history object has not been passed as parameter so signOut redirect will not trigger');
  // Sign out from cognito and clear user object
  await Auth.signOut();
  dispatch(Creators.clear());
  // Redirect if possible
  if (history) history.push('/');
};

export const pinpointConfig = {
  disabled: true,
  autoSessionRecord: true,
  AWSPinpoint: {
    appId: '490e7b5f93804149bfc2b1e7cccc848c',
    region: 'us-east-1',
  },
};

export const userSubjectCheck = (user, subject) => ((Object.keys(user).length >= 0)
    && (user.pool && user.pool.userPoolId)
    && ((subject === mapPoolIdToSubject(user.pool.userPoolId))
      || subject === AuthenticatorSubject.ANY));

export const setToApplicantsPool = () => Amplify.configure({
  Auth: applicantsAuthConfig,
  Analytics: pinpointConfig,
});

export const setToClientsPool = () => Amplify.configure({
  Auth: clientsAuthConfig,
  Analytics: pinpointConfig,
});

export const setPool = (pool) => {
  // Check if pool is of right type
  if (pool === undefined || typeof pool !== 'string') {
    throw new Error('Invalid pool param!');
  }
  // Set it
  const applicantsPool = applicantsAuthConfig.userPoolId;
  const clientsPool = clientsAuthConfig.userPoolId;
  if (pool === applicantsPool) return setToApplicantsPool();
  if (pool === clientsPool) return setToClientsPool();
  // if you reach here, then you are also screwed
  throw new Error('Unrecognized user pool!');
};

export const setPoolBySubject = (subject) => {
  if (subject === AuthenticatorSubject.CLIENT) return setToClientsPool();
  if (subject === AuthenticatorSubject.APPLICANT) return setToApplicantsPool();
  throw new Error('Invalid subject!');
};

export const configure = {
  setToApplicantsPool, setToClientsPool, setPool, setPoolBySubject,
};
