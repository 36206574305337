import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  userSubjectCheck,
  currentAuthenticatedUser,
  currentSession,
  setPool,
} from 'services/auth';
import { Creators } from 'state/User';
import LoadingOverlay from 'core/LoadingOverlay';
import { Error404Page } from 'screens/ErrorScreen';

import AuthenticatorSubject from './AuthenticatorSubject';

const mapStateToProps = state => ({ user: state.user });

const mapDispatchToProps = (dispatch) => {
  const { update } = Creators;
  return {
    updateUser: user => dispatch(update(user)),
  };
};

const TOKEN_REFRESH_INTERVAL = process.env.REACT_APP_TOKEN_REFRESH_INTERVAL ?? 9000;

class Authenticator extends PureComponent {

  static propTypes = {
    children: PropTypes.node.isRequired, // pass this
    subject: PropTypes.oneOf(AuthenticatorSubject.values()).isRequired, // and this
    // redux stuff
    user: PropTypes.object.isRequired, // eslint-disable-line
    updateUser: PropTypes.func.isRequired,
    omitError: PropTypes.bool,
  }

  state = {
    loading: true,
    error: false,
    refreshInterval: 0,
    user: {},
  }

  async componentDidMount() {
    await this.authenticate();

    this.setUpTokenRefresh();
  }

  componentWillUnmount() {
    const { refreshInterval } = this.state;

    clearInterval(refreshInterval);

    this.setState({ refreshInterval });
  }

  setUpTokenRefresh() {
    const refreshInterval = setInterval(this.refreshTokens, TOKEN_REFRESH_INTERVAL);

    this.setState({ refreshInterval });
  }

  refreshTokens = async () => {
    const { user } = this.state;

    const session = await currentSession();
    const userWithSession = {
      ...user,
      signInUserSession: session,
    };

    this.props.updateUser(userWithSession);
  }

  async authenticate() {
    const storedUser = this.props.user;
    const { subject } = this.props;

    if (storedUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
      this.setState({ loading: false });
      return;
    }
    if (!userSubjectCheck(storedUser, subject)) {
      this.setState({ error: true });
      return;
    }

    try {
      await setPool(storedUser.pool.userPoolId);
      const user = await currentAuthenticatedUser();

      this.setState({ loading: false, user }, this.refreshTokens);
    } catch (err) {
      // eslint-disable-next-line
      console.warn(err);
      this.setState({ error: true });
    }
  }

  render() {
    const { loading, error } = this.state;
    const { children, omitError } = this.props;

    if (error && !omitError) {
      return <Error404Page {...children.props} />;
    }

    const childrenRender = React.cloneElement(children, children.props);
    return loading && !error ? <LoadingOverlay /> : childrenRender;
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(Authenticator);