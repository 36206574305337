import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'localforage';
import logger from 'redux-logger';

import * as ApiV1 from 'services/Api';
import * as ApiV2 from 'services/v2/Api';
import { mapPoolIdToSubject } from 'utils/Authenticator';

import reducer from './reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
};

const persistedReducer = persistReducer(persistConfig, reducer);

let middleware = []; // production middleware
if (process.env.NODE_ENV !== 'production') {
  middleware = [...middleware, logger]; // additional dev middleware
}

const store = createStore(persistedReducer, applyMiddleware(...middleware));
const persistor = persistStore(store);

const tokenSelector = (state) => {
  if (!state.user.signInUserSession) {
    return '';
  }

  return state.user.signInUserSession.idToken.jwtToken;
};

const groupIdSelector = (state) => {
  if (!state.user.pool) {
    return '';
  }

  return state.user.pool.userPoolId;
};

store.subscribe(() => {
  const state = store.getState();
  const token = tokenSelector(store.getState());
  ApiV1.setAuthHeader(token);
  ApiV2.setAuthHeader(token);

  const userGroup = mapPoolIdToSubject(groupIdSelector(state));
  ApiV1.setApiUserGroup(userGroup);
  ApiV2.setApiUserGroup(userGroup);
});

export default () => ({ store, persistor });
