export const SM_BEGIN = '768px';
export const MD_BEGIN = '992px';
export const LG_BEGIN = '1200px';

export const cssAt = {
  sm: `@media (max-width: ${SM_BEGIN}) and (min-width: 0px)`,
  md: `@media (max-width: ${MD_BEGIN}) and (min-width: ${SM_BEGIN})`,
  lg: `@media (max-width: ${LG_BEGIN}) and (min-width: ${MD_BEGIN})`,
};

export const cssFrom = {
  sm: `@media (max-width: ${SM_BEGIN})`,
  md: `@media (max-width: ${MD_BEGIN})`,
  lg: `@media (max-width: ${LG_BEGIN})`,
};

export const sm = '-768';
export const md = '768-992';
export const lg = '992-1200';
export const xl = '+1200';

export default {
  sm,
  md,
  lg,
  xl,
};
