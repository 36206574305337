const getTheme = async () => {
  const themeName = process.env.REACT_APP_THEME;
  switch (themeName) {
    case 's1': {
      const module = await import('./themes/s1Theme');
      return module.default;
    }
    case 'viz':
    default: {
      const module = await import('./themes/defaultTheme');
      return module.default;
    }
  }
};

export default getTheme;
