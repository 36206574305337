import styled from 'styled-components';

import { cssFrom } from 'styles/breakpoints';

const Container = styled.div`
  background-color: ${(props) => props.theme.palette.tertiary.main};
  position: fixed;
  height: 100%;
  z-index: 998;

  ${cssFrom.md} {
    position: relative;
    height: auto;
    overflow: hidden;
  }

  ${cssFrom.sm} {
    overflow: hidden;
    border-bottom: 7.5px solid ${(props) => props.theme.palette.primary.main};
    position: fixed;
    width: 100vw;
  }
`;

const Item = styled.div`
  padding: 1em;
  text-align: center;
  color: ${(props) => props.theme.palette.primary.contrastText};
  font-size: 1.5em;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.main};
    color: ${(props) => props.theme.palette.primary.contrastText};
  }

  ${cssFrom.sm} {
    &:hover {
      background-color: transparent;
      color: ${(props) => props.theme.palette.primary.contrastText};
    }
  }
`;

const VizLogo = styled.img`
  width: 1em;
  height: 1em;
`;

export default {
  Container,
  Item,
  VizLogo,
};
