import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import Authenticator, { AuthenticatorSubject, withAuthenticator } from 'utils/Authenticator';

export default class PrivateRoute extends PureComponent {

  static propTypes = {
    component: PropTypes.func,
    subject: PropTypes.oneOf(AuthenticatorSubject.values()).isRequired,
    children: PropTypes.node,
  }

  render() {
    const {
      component, subject, children, ...rest
    } = this.props;
    if (children) {
      return (
        <Route {...rest}>
          { props => (
            <Authenticator subject={subject}>
              { React.cloneElement(children, props) }
            </Authenticator>
          )}
        </Route>
      );
    }
    return (
      <Route
        {...rest}
        component={withAuthenticator(component, subject)}
      />
    );
  }

}
