// @flow
import React from 'react';
import styled, { css } from 'styled-components';

import PageFrame from 'core/PageFrame';
import { cssAt } from 'styles/breakpoints';

import TextContent from './TextContent';
import penguin404 from './penguin404.svg';
import waterFloat from './waterFloat';

const FlexContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const PageContainer = styled(FlexContainer)`
  width: 100%;
  overflow: hidden;

  height: 100vh;
  padding-top: 10%;

  ${cssAt.md} {
    padding-top: 0;
  }

  ${cssAt.md} {
    height: calc(100vh - 74px);
  }
`;

const Backgroud = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  &::after {
    box-sizing: border-box;
    overflow: hidden;
    content: '';
    position: absolute;
    top: 60%;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #74b9ff;
  }
`;

const animationRule = css`
  ${waterFloat} 3s ease infinite;
`;

const PenguinImage = styled.img`
  display: flex;
  width: 50%;
  height: 75%;
  animation: ${animationRule};
  animation-direction: alternate;
`;

type Props = {
  title: string,
  subtitle: string,
  errorMessage?: string,
  includeFrame?: boolean,
};

export default function ErrorScreen(props: Props) {
  const {
    title, subtitle, errorMessage,
    includeFrame,
  } = props;

  const Content = (
    <PageContainer>
      <Backgroud />
      <FlexContainer>
        <TextContent
          title={title}
          subtitle={subtitle}
          errorMessage={errorMessage}
        />
      </FlexContainer>
      <FlexContainer>
        <PenguinImage
          src={penguin404}
          alt="Penguin"
        />
      </FlexContainer>
    </PageContainer>
  );


  return includeFrame
    ? (
      <PageFrame fill>
        { Content }
      </PageFrame>
    )
    : Content;
}

ErrorScreen.defaultProps = {
  errorMessage: '',
  includeFrame: true,
};
