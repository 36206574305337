import axios from 'axios';

const { REACT_APP_API_V2 } = process.env;

const Api = axios.create();

export const setAuthHeader = (token) => {
  Api.defaults.headers.common.Authorization = `${token}`;
};

export const setApiUserGroup = (userGroup) => {
  Api.defaults.baseURL = `${REACT_APP_API_V2}/${userGroup}`;
};

export default Api;
