import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';

const LoadingContainer = styled.div`
  width: 100px;
  height: 100px;
  position: relative;
`;

const LoadingBit = styled.div`
  @keyframes dotAnimation {
    0%, 10% {
      opacity: 0;
    }
    35%, 75% {
      opacity: 1;
    }
    80%, 100% {
      opacity: 0;
    }
  }

  @keyframes rightAnimation {
    0%, 10% {
      opacity: 0;
      transform: translateX(200px) rotate(45deg);
    }
    35%, 75% {
      opacity: 1;
      transform: translateX(0) rotate(0deg);
    }
    80%, 100% {
      opacity: 0;
      transform: translateX(200px) rotate(45deg);
    }
  }

  @keyframes bottomAnimation {
    0%, 10% {
      opacity: 0;
      transform: translateY(200px);
    }
    35%, 75% {
      opacity: 1;
      transform: translateY(0) rotate(0deg);
    }
    80%, 100% {
      opacity: 0;
      transform: translateY(200px);
    }
  }

  @keyframes leftAnimation {
    0%, 10% {
      opacity: 0;
      transform: translateX(-200px) rotate(45deg);
    }
    35%, 75% {
      opacity: 1;
      transform: translateX(0) rotate(0deg);
    }
    80%, 100% {
      opacity: 0;
      transform: translateX(-200px) rotate(45deg);
    }
  }

  float: left;
  width: 50%;
  height: 50%;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: 100% 100%;
  }

  ${props => props.dot && css`
    margin-left: 25%;
    margin-right: 25%;
    transform: scale(0.5) translateY(15px);
    &:before {
      border-radius: 100%;
      background-color: ${props.theme.palette.error.main};
      animation: dotAnimation 6s ease 1.5s infinite both;
    }
  `}

  ${props => props.left && css`
    transform: scale(0.35, 1.2) skew(60deg) translateX(29px);
    &:before {
      background-color: ${props.theme.palette.primary.main};
      animation: leftAnimation 6s ease 1s infinite both;
    }
  `}

  ${props => props.right && css`
    transform: scale(0.35, 1.2) skew(-60deg) translateX(-29px);
    &:before {
      background-color: ${props.theme.palette.primary.main};
      animation: rightAnimation 6s ease 0s infinite both;
    }
  `}

  ${props => props.bottom && css`
    margin-left: 25%;
    margin-right: 25%;
    transform: scale(0.26, 0.5) translateY(-44px) rotate(45deg);
    &:before {
      background-color: ${props.theme.palette.primary.main};
      animation: bottomAnimation 6s ease 0.5s infinite both;
    }
  `}

`;

export default class LoadingIndicator extends PureComponent {

  render() {
    return (
      <LoadingContainer>
        <LoadingBit dot />
        <LoadingBit left />
        <LoadingBit right />
        <LoadingBit bottom />
      </LoadingContainer>
    );
  }

}
