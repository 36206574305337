import React, { PureComponent } from 'react';

import Authenticator from './Authenticator';

const withAuthenticator = (WrappedComponent, subject, omitError = false) => (
  class WithAuthenticator extends PureComponent {

    render() {
      return (
        <Authenticator subject={subject} omitError={omitError}>
          <WrappedComponent {...this.props} />
        </Authenticator>
      );
    }

  }
);

export default withAuthenticator;
