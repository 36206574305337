import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {};

export const { Types, Creators } = createActions({
  add: ['user'],
  update: ['user'],
  clear: null,
});

const add = (state, { user }) => user;
const update = (state, { user }) => ({ ...state, ...user });
const clear = () => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [Types.ADD]: add,
  [Types.UPDATE]: update,
  [Types.CLEAR]: clear,
});
