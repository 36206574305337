import {
  library,
} from '@fortawesome/fontawesome-svg-core';
import {
  faFacebookSquare,
  faGooglePlusSquare,
  faFacebook,
  faLinkedin,
  faTwitterSquare,
  faFacebookMessenger,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import {
  faAlignLeft,
  faArrowLeft,
  faSignInAlt,
  faQuestionCircle,
  faArrowRight,
  faUserPlus,
  faSearch,
  faMapMarkerAlt,
  faFileImport,
  faThumbsUp,
  faFileAlt,
  faThumbsDown,
  faHeadset,
  faHourglassHalf,
  faChevronRight,
  faTachometerAlt,
  faQuoteLeft,
  faIdCard,
  faFlag,
  faStar,
  faCircle,
  faClock,
  faTh,
  faCog,
  faUserCircle,
  faLink,
  faCopy,
  faRocket,
  faShareAlt,
  faList,
  faTimes,
  faBars,
  faDownload,
  faExclamationTriangle,
  faExpand,
  faFileVideo,
  faFilm,
  faDoorOpen,
  faGlobeAmericas,
  faHandshake,
  faHandPaper,
  faMoneyBill,
  faPause,
  faPencilAlt,
  faPlay,
  faPlus,
  faPlusCircle,
  faSquare,
  faTimesCircle,
  faTrashAlt,
  faTrash,
  faUser,
  faUserClock,
  faFilter,
  faVideo,
  faFileDownload,
  faQuestion,
  faCheckCircle,
  faGem,
  faChevronCircleRight,
  faEyeDropper,
  faEye,
  faEyeSlash,
  faCheck,
  faMale,
  faFemale,
  faLightbulb,
  faUpload,
  faFile,
  faPaperPlane,
  faSave,
  faPhone,
  faEnvelope,
  faCode,
  faInfo,
  faGrinBeam,
  faListUl,
  faMicrophone,
  faCommentDots,
  faTv, faEdit,
  faExclamationCircle,
  faUsers,
  faMicrophoneSlash,
  faMinus,
  faChartLine,
} from '@fortawesome/free-solid-svg-icons';

import {
  faFileVideo as farFileVideo,
  faTimesCircle as farTimesCircle,
  faCalendarAlt,
  faClipboard,
  faIdBadge,
  faHourglass,
  faStar as farStar,
  faUser as farUser,
  faCheckCircle as farCheckCircle,
} from '@fortawesome/free-regular-svg-icons';

const loadIcons = () => library.add(
  // companies
  faFacebookSquare,
  faGooglePlusSquare,
  faLinkedin,
  faTwitterSquare,
  faFacebookMessenger,
  // solid
  faAlignLeft,
  faSearch,
  faCheckCircle,
  faChevronCircleRight,
  faUserPlus,
  faFilter,
  faArrowLeft,
  faArrowRight,
  faChevronRight,
  faThumbsUp,
  faThumbsDown,
  faFlag,
  faTachometerAlt,
  faQuoteLeft,
  faIdCard,
  faStar,
  faCircle,
  faClock,
  faCog,
  faUserCircle,
  faLink,
  faRocket,
  faMapMarkerAlt,
  faShareAlt,
  faSignInAlt,
  faHeadset,
  faTimes,
  faCopy,
  faDownload,
  faExclamationTriangle,
  faExpand,
  faFileVideo,
  faFileImport,
  farFileVideo,
  faHourglassHalf,
  faFilm,
  faGlobeAmericas,
  faHandshake,
  faIdCard,
  faMoneyBill,
  faCopy,
  faPause,
  faPencilAlt,
  faPlay,
  faList,
  faPlus,
  faPlusCircle,
  faQuoteLeft,
  faRocket,
  faShareAlt,
  faBars,
  faTh,
  faSquare,
  faTachometerAlt,
  faTimes,
  faTimesCircle,
  farTimesCircle,
  faTrashAlt,
  faUser,
  faFileAlt,
  faUserClock,
  faDoorOpen,
  faVideo,
  faFileDownload,
  faQuestion,
  faGem,
  faEyeDropper,
  faEye,
  faEyeSlash,
  faFacebook,
  faCheck,
  faTwitter,
  faMale,
  faFemale,
  faLightbulb,
  faUpload,
  faFile,
  faPaperPlane,
  faSave,
  faQuestionCircle,
  faPhone,
  faEnvelope,
  faCode,
  faInfo,
  faGrinBeam,
  faListUl,
  faMicrophone,
  faCommentDots,
  faTv,
  faEdit,
  faPlus,
  faTrashAlt,
  faTrash,
  faExclamationCircle,
  faUsers,
  faMicrophoneSlash,
  faHandPaper,
  faCalendarAlt,
  faClipboard,
  faIdBadge,
  faHourglass,
  faMinus,
  farStar,
  farUser,
  farCheckCircle,
  faChartLine,
);
export default loadIcons;
