// @flow
import React from 'react';
import styled, { css } from 'styled-components';
import Grid from 'hedron';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cssFrom } from 'styles/breakpoints';

const IconHolder = styled(Grid.Box)`
  margin-right: 1.6em;
`;

type FilteredIconProps = {
  active: boolean,
  highlighted: boolean,
}
const FilteredIcon = ({ active, highlighted, ...props }: FilteredIconProps) => (
  <FontAwesomeIcon {...props} />
);

const Icon = styled(FilteredIcon)`
  line-height: 200%;
  font-size: 1.5em;
  ${props => props.active && css`
    color: ${props.theme.palette.primary.contrastText};
  `};

  ${props => props.highlighted && css`
    color: ${props.theme.palette.primary.contrastText};
  `};
`;

const Text = styled.p`
  line-height: 100%;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
  text-transform: uppercase;
  color: ${props => props.theme.palette.text.primary};
`;

const Title = styled(Text)`
  font-size: 16px;
  ${props => props.active && css`
    color: ${props.theme.palette.primary.contrastText};
  `};

  ${props => props.highlighted && css`
    color: ${props.theme.palette.primary.contrastText};
  `};

`;

const Sub = styled(Text)`
  font-size: 10px;
  ${props => props.active && css`
    color: ${props.theme.palette.primary.main};
  `};

  ${props => props.highlighted && css`
    color: ${props.theme.palette.primary.contrastText};
  `};

`;

const Container = styled(Grid.Box)`
  background-color: ${props => props.theme.palette.background.lighter};
  padding: 1.2em 2.4em;
  border-right: 2px solid ${props => props.theme.palette.grey['600']};
  height: 100%;
  min-width: 18em;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.palette.background.light};
  }

  ${cssFrom.sm} {
    min-width: 100vw;
    border-right: none;
    border-bottom: 2px solid ${props => props.theme.palette.grey['600']};
    background-color: ${props => props.theme.palette.background.main};
  }
  
  ${props => props.flex && css`
    min-width: inherit;
    display: flex;
    flex: 1;
  `}

  ${props => props.active && css`
    background-color: ${props.theme.palette.tertiary.main};

    &:hover {
      background-color: ${props.theme.palette.tertiary.main};
    }

    ${cssFrom.sm} {
      background-color: ${props.theme.palette.tertiary.main};

      &:hover {
        background-color: ${props.theme.palette.tertiary.main};
      }
    }
  `};

  ${props => props.highlighted && css`
    background-color: ${props.theme.palette.primary.main};

    &:hover {
      background-color: ${props.theme.palette.primary.dark};
    }

    ${cssFrom.sm} {
      background-color: ${props.theme.palette.primary.main};

      &:hover {
        background-color: ${props.theme.palette.primary.dark};
      }
    }

  `};

`;


export default {
  Container,
  IconHolder,
  Icon,
  Title,
  Sub,
};
