// @flow
import React, { createContext, useContext, useState } from 'react';


const PrivateJobsContext = createContext();

type Props = {
  children: React.Node;
}
export default function PrivateJobsContextProvider({ children }: Props) {
  const [credentials, setCredentials] = useState<Record<string, string>>(null);
  const setCredentialsForJob = (id: string, pass: string) => {
    setCredentials({
      ...credentials,
      [id]: pass,
    });
  };

  return (
    <PrivateJobsContext.Provider value={{
      credentials,
      setCredentialsForJob,
    }}
    >
      {children}
    </PrivateJobsContext.Provider>
  );
}
export const usePrivateJobsContext = () => useContext(PrivateJobsContext);
