import { css } from 'styled-components';

import { lighten } from 'styles/helpers';

import { Spinner } from './Button';

export const primaryVariant = (theme) => css`
  color: ${theme.palette.primary.contrastText};
  background-color: ${theme.palette.primary.main};
  &:active {
    background-color: ${lighten(theme.palette.primary.main, 0.1)};
  }
  & > ${Spinner} {
    &:first-child {
      border: 2px solid ${theme.palette.primary.contrastText};
    }
  }
`;

export const secondaryVariant = (theme) => css`
  color: ${theme.palette.secondary.contrastText};
  background-color: ${theme.palette.secondary.dark};
  &:active {
    background-color: ${lighten(theme.palette.secondary.dark, 0.1)};
  }
  & > ${Spinner} {
    &:first-child {
      border: 2px solid ${theme.palette.secondary.contrastText};
    }
  }
`;

export const secondaryLightVariant = (theme) => css`
  color: ${theme.palette.secondary.contrastText};
  background-color: ${theme.palette.secondary.main};
  &:active {
    background-color: ${lighten(theme.palette.secondary.main, 0.1)};
  }
  & > ${Spinner} {
    &:first-child {
      border: 2px solid ${theme.palette.secondary.contrastText};
    }
  }
`;

export const whiteVariant = (theme) => css`
  color: ${theme.palette.tertiary.dark};
  background-color: ${theme.palette.tertiary.contrastText};
  border-color: ${theme.palette.tertiary.light};
  border-style: solid;
  &:active {
    background-color: ${lighten(theme.palette.secondary.main, 0.1)};
  }
  & > ${Spinner} {
    &:first-child {
      border: 2px solid ${theme.palette.secondary.contrastText};
    }
  }
`;

export const dangerVariant = (theme) => css`
  color: ${theme.palette.error.contrastText};
  background-color: ${theme.palette.error.main};
  &:active {
    background-color: ${lighten(theme.palette.error.main, 0.1)};
  }
  & > ${Spinner} {
    &:first-child {
      border: 2px solid ${theme.palette.error.contrastText};
    }
  }
`;

export const baseVariant = (theme) => css`
  color: ${theme.palette.text.primary};
  background-color: ${theme.palette.background.main};
  &:active {
    background-color: ${lighten(theme.palette.background.main, 0.3)};
  }
  & > ${Spinner} {
    &:first-child {
      border: 2px solid ${theme.palette.text.primary};
    }
  }
`;

// #166b8f

export const applyVariant = (variant, theme) => {
  switch (variant) {
    case 'primary':
      return primaryVariant(theme);
    case 'secondary':
      return secondaryVariant(theme);
    case 'danger':
      return dangerVariant(theme);
    case 'secondaryLight':
      return secondaryLightVariant(theme);
    case 'white':
      return whiteVariant(theme);
    default:
      return baseVariant(theme);
  }
};
